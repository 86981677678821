import { destructure } from "@solid-primitives/destructure";
import { clamp } from "lodash-es";
import { createEffect } from "solid-js";
import { initTooltip } from "~/components/Tooltip";
import type { TableResponse } from "~/types/TableResponse";
import { BROWSING_STATE, REPERTOIRE_STATE, USER_STATE } from "~/utils/app_state";
import { c, stylex } from "~/utils/styles";

export const MasteryProgressBar = (props: { tableResponse: TableResponse }) => {
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const mastery = () =>
		REPERTOIRE_STATE().masteryFromEpd[activeRepertoireId()][
			props.tableResponse.suggestedMove?.epdAfter!
		];
	const backgroundColor = c.gray[28];
	const completedColor = c.colors.success;
	const { completed, progress } = destructure(() => {
		let completed = mastery() === 1;
		let progress = clamp(mastery() * 100, 5, 90);
		return { completed, progress };
	});
	const inProgressColor = () => (progress() < 20 ? c.red[65] : c.orange[65]);
	createEffect(() => {});
	return (
		<div
			style={stylex(c.column, c.fullWidth)}
			class="py-1"
			ref={(ref) => {
				initTooltip({
					ref,
					content: () => {
						let content = `How well you know this move and the moves after it.`;
						if (USER_STATE().user?.isAdmin) {
							content += `Mastery is ${mastery()}`;
						}
						return content;
					},
					maxWidth: 160,
				});
			}}
		>
			<div class="w-full rounded-full overflow-hidden h-1" style={stylex(c.bg(backgroundColor))}>
				<div
					style={stylex(
						c.width(completed() ? "100%" : `${progress()}%`),
						c.bg(completed() ? completedColor : inProgressColor()),
						c.fullHeight,
					)}
				/>
			</div>
		</div>
	);
};
