import { destructure } from "@solid-primitives/destructure";
import { includes, isEmpty, isNil } from "lodash-es";
import { Show, createEffect } from "solid-js";
import {
	BROWSING_STATE,
	REPERTOIRE_STATE,
	TRAINERS_STATE,
	UI,
	quick,
	useMode,
} from "~/utils/app_state";
import type { ChessboardInterface } from "~/utils/chessboard_interface";
import { heightAboveChessboardClasses } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { AnalyzeOnLichessButton } from "./AnalyzeOnLichessButton";
import { CMText } from "./CMText";
import { FadeInOut } from "./FadeInOut";
import { Pressable } from "./Pressable";
import { ReviewComplete } from "./ReviewComplete";
import { VERTICAL_BREAKPOINT } from "./SidebarLayout";
import { FirstLineSavedOnboarding, OnboardingIntro } from "./SidebarOnboarding";

export const BackSection = (props: { chessboard?: ChessboardInterface }) => {
	const mode = useMode();
	const view = () => UI().currentView();
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const moveLog = () => BROWSING_STATE().chessboard.get((v) => v).moveLog;
	const responsive = useResponsiveV2();
	const vertical = () => responsive().bp < VERTICAL_BREAKPOINT;
	const { backButtonAction } = destructure(() => {
		let backButtonAction: (() => void) | null = null;
		let backEntry = UI().getBackEntry();

		if (view()) {
			backButtonAction = () => {
				quick((_s) => {
					UI().backOne();
				});
			};
		}
		if (TRAINERS_STATE().getActiveTool() === "blunder-puzzles" && UI().sidebarStack.length === 1) {
			backButtonAction = null;
		}
		if (onboarding().isOnboarding) {
			if (
				includes([OnboardingIntro, FirstLineSavedOnboarding, ReviewComplete], view()?.component) ||
				(onboarding().isOnboarding && mode() === "build" && isEmpty(moveLog()))
			) {
				backButtonAction = null;
			}
		}
		if (backEntry?.behavior === "hide") {
			backButtonAction = null;
		}
		return { backButtonAction };
	});
	createEffect(() => {
		quick((s) => {
			s.ui.backButtonCallback.clear();
			if (backButtonAction()) {
				s.ui.backButtonCallback.add(() => {
					backButtonAction()?.();
				});
			}
		});
	});

	const isOpen = () => !isNil(backButtonAction());

	return (
		<FadeInOut
			style={stylex(c.column, vertical() && c.height(isOpen() ? 36 : 12))}
			class={heightAboveChessboardClasses}
			open={isOpen()}
			// className="transition-height"
		>
			<div class={"row padding-sidebar h-full items-center justify-between"}>
				<Pressable
					id="back-button"
					onPress={() => {
						quick(() => {
							if (backButtonAction()) {
								backButtonAction()?.();
							}
						});
					}}
					style={stylex(c.unshrinkable, c.column, c.justifyCenter)}
					class={
						"text-md text-tertiary &hover:text-secondary place-items-center py-2 md:self-end md:pb-8"
					}
				>
					<CMText style={stylex(c.weightBold, c.row, c.alignCenter)}>
						<i class="fa fa-arrow-left pr-2" />
						Back
					</CMText>
				</Pressable>
				<Show when={vertical() && !onboarding().isOnboarding && props.chessboard}>
					<AnalyzeOnLichessButton chessboard={props.chessboard!} />
				</Show>
			</div>
		</FadeInOut>
	);
};
