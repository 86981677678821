import { destructure } from "@solid-primitives/destructure";
import { find, isEmpty } from "lodash-es";
import { type Accessor, createSignal, onMount } from "solid-js";
import { APP_STATE, UI, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { isAndroid, isNative } from "~/utils/env";
import {
	PRODUCT_CHESSBOOK_ANNUAL,
	PRODUCT_CHESSBOOK_MONTHLY,
	PRODUCT_CHESSBOOK_PRO_ANDROID,
	offerIsAnnual,
} from "~/utils/in_app_purchases";
import { getMaxMovesPerSideOnFreeTier } from "~/utils/payment";
import { getPricing } from "~/utils/pricing";
import { trackEvent } from "~/utils/trackEvent";
import { SidebarTemplate } from "./SidebarTemplate";

export const UpgradeSubscriptionView = (props: { pastLimit: boolean }) => {
	const [loading, setLoading] = createSignal(false);
	const products = () => APP_STATE().inAppPurchaseState.products;
	const loadingProducts = () => isNative && isEmpty(products());
	const requestProPlan = (props: { offer: CdvPurchase.Offer } | { annual: boolean }) => {
		const isAnnual = "annual" in props ? props.annual : offerIsAnnual(props.offer);
		setLoading(true);
		trackEvent("upgrade.subscribe", {
			type: isAnnual ? "annual" : "monthly",
		});
		quick((s) => {
			if ("offer" in props) {
				props.offer
					.order()
					.catch(() => {
						quick((_s) => {
							UI().backOne();
						});
					})
					.then((_x) => {
						quick((s) => {
							s.userState.user!.subscribed = true;
							UI().backOne();
						});
					});
			} else {
				s.userState.getCheckoutLink(props.annual).then((url) => {
					window.location.href = url;
				});
			}
		});
	};
	onMount(() => {
		trackEvent("upgrade.shown");
	});
	const [monthlyOffer, annualOffer]: readonly [
		Accessor<CdvPurchase.Offer | null>,
		Accessor<CdvPurchase.Offer | null>,
	] = destructure(() => {
		const findOffer = (annual: boolean): CdvPurchase.Offer | null => {
			const product =
				products()[
					isAndroid
						? PRODUCT_CHESSBOOK_PRO_ANDROID
						: annual
							? PRODUCT_CHESSBOOK_ANNUAL
							: PRODUCT_CHESSBOOK_MONTHLY
				];
			if (!product) {
				return null;
			}
			if (product.offers.length === 1) {
				return product.offers[0];
			}
			return (
				find(product.offers, (x) => {
					if (annual) {
						return offerIsAnnual(x);
					}
					return !offerIsAnnual(x);
				}) ?? null
			);
		};
		return [findOffer(false), findOffer(true)];
	});
	let webMonthlyPriceTag = () => {
		let price = getPricing()[0];
		if (Math.round(price) === price) {
			return `${price}`;
		}
		return `${price.toFixed(2)}`;
	};
	return (
		<>
			<SidebarTemplate
				actions={
					loading() || loadingProducts()
						? []
						: [
								{
									onPress: () => {
										requestProPlan(isNative ? { offer: monthlyOffer()! } : { annual: false });
									},
									text: "Upgrade to Chessbook Pro - Monthly",
									subtext: isNative
										? `${monthlyOffer()!.pricingPhases[0].price}/month`
										: `$${webMonthlyPriceTag()}/month`,
									style: "primary",
								},
								{
									onPress: () => {
										requestProPlan(isNative ? { offer: annualOffer()! } : { annual: true });
									},
									text: "Upgrade to Chessbook Pro - Annual",
									subtext: isNative
										? `${annualOffer()!.pricingPhases[0].price}/year (save ${Math.round(
												(1 -
													annualOffer()!.pricingPhases[0].priceMicros /
														(monthlyOffer()!.pricingPhases[0].priceMicros * 12)) *
													100,
											)}%)`
										: `$${getPricing()[1]}/year (save ${((1 - getPricing()[1] / (getPricing()[0] * 12)) * 100).toFixed(0)}%)`,
									style: "primary",
								},
							]
				}
				header={
					props.pastLimit ? `You’ve reached the limit on the free plan` : "Upgrade your account"
				}
				bodyPadding={true}
				loading={loading()}
			>
				<p class={clsx("text-secondary leading-5")}>
					Free users can add and review {getMaxMovesPerSideOnFreeTier()[0]} moves per color. Upgrade
					to unlock unlimited moves!
				</p>
			</SidebarTemplate>
			<p class={clsx("text-secondary leading-5 text-xs pt-4 padding-sidebar")}>
				If you ever cancel, you'll still have full access to your repertoire. You'll be able to
				review online mistakes, view model games, export your moves, and practice the most common{" "}
				{getMaxMovesPerSideOnFreeTier()[0]} moves per side.
			</p>
		</>
	);
};
