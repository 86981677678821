import { Motion } from "@motionone/solid";
import { destructure } from "@solid-primitives/destructure";
import { capitalize, first, noop } from "lodash-es";
import {
	type Component,
	For,
	type JSX,
	type JSXElement,
	Show,
	createEffect,
	createSignal,
	onMount,
} from "solid-js";
import { Spacer } from "~/components/Space";
import { DEFAULT_LICHESS_SCOPES } from "~/constants";
import { Line } from "~/types/Line";
import { SIDES, type Side } from "~/types/Side";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick, useUserState } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { useLineEcoCode } from "~/utils/eco_codes";
import { freezeWhileShown } from "~/utils/freeze_chessboard";
import { showUpgradeIfNeeded } from "~/utils/pricing";
import { DEFAULT_ELO_RANGE } from "~/utils/repertoire_state";
import { c, stylex } from "~/utils/styles";
import { renderThreshold } from "~/utils/threshold";
import { trackEvent } from "~/utils/trackEvent";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { getRecommendedMissThreshold } from "~/utils/user_state";
import { BigBar } from "./BigBar";
import { Bullet } from "./Bullet";
import { CMText } from "./CMText";
import { ConnectChesscom } from "./ConnectedAccounts";
import { LichessNeedNewScopes } from "./LichessNeedNewScopes";
import { LoadingView } from "./LoadingView";
import { LoginSidebar } from "./LoginSidebar";
import { RatingSelection } from "./RatingSelection";
import { SelectLichessStudy } from "./SelectLichessStudy";
import type { SidebarAction } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextArea } from "./TextInput";
import { useOutsideClick } from "./useOutsideClick";

export const OnboardingIntro = () => {
	const bullets = ["Adding your first moves", "Practicing them using spaced repetition"];
	freezeWhileShown(OnboardingIntro);
	return (
		<SidebarTemplate
			header="Let's start creating your repertoire!"
			bodyPadding={true}
			actions={[
				{
					onPress: () => {
						quick((_s) => {
							UI().pushView(SetRatingOnboarding);
							trackEvent("onboarding.get_started");
						});
					},
					style: "focus",
					text: "Get started",
				},
			]}
		>
			<CMText class={"body-text"}>
				This setup process will introduce some of the key ideas behind Chessbook.
			</CMText>
			<Spacer between={["body-text", "body-text"]} />
			<p class={"body-text"}>This walkthrough will cover:</p>
			<div style={stylex(c.gridColumn({ gap: 8 }), c.pt(12))}>
				{bullets.map((bullet) => (
					<Bullet>{bullet}</Bullet>
				))}
			</div>
			<Spacer between={["body-text", "body-text"]} />
			<p class="body-text">It should only take a few minutes to complete.</p>
		</SidebarTemplate>
	);
};

export const SetRatingOnboarding = () => {
	const [user] = useUserState((s) => [s.user]);
	freezeWhileShown(SetRatingOnboarding);
	const setAndContinue = (range: string | null) => {
		quick((s) => {
			let activeRange = range ?? s.userState.user?.ratingRange ?? DEFAULT_ELO_RANGE.join("-");
			const recommendedThreshold = getRecommendedMissThreshold(activeRange);
			UI().pushView(LoadingView);

			quick((s) => {
				s.userState
					.updateUserRatingSettings({
						ratingRange: activeRange,
						missThreshold: recommendedThreshold * 100,
					})
					.then(() => {
						animateSidebar("right");
						quick((s) => {
							s.repertoireState.browsingState.goToBuildOnboarding();
						});
					});
			});
		});
	};
	onMount(() => {
		trackEvent("onboarding.rating.shown");
	});
	return (
		<SidebarTemplate
			header={"What is your current rating?"}
			bodyPadding={true}
			actions={[
				{
					onPress: () => {
						trackEvent("onboarding.rating.set", {
							ratingRange: user()?.ratingRange,
							ratingSource: user()?.ratingSystem,
						});
						setAndContinue(null);
					},
					style: "focus",
					text: "Set rating and continue",
				},
				{
					onPress: () => {
						trackEvent("onboarding.rating.dont_know");
						setAndContinue("0-1000");
					},
					style: "primary",
					text: "I don't know, skip this step",
				},
			]}
		>
			<CMText class={"body-text"}>
				This is used to determine which moves your opponents are likely to play.
			</CMText>
			<Spacer between={["body-text", "form"]} />
			<RatingSelection />
		</SidebarTemplate>
	);
};

export const ChooseToCreateAccountOnboarding = () => {
	onMount(() => {
		trackEvent("onboarding.create_account.shown");
	});
	freezeWhileShown(ChooseToCreateAccountOnboarding);
	return (
		<SidebarTemplate
			header={"Would you like to create an account?"}
			bodyPadding={true}
			actions={[
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("onboarding.create_account.yes");
							UI().pushView(LoginSidebar, {
								props: { authType: "register" },
							});
						});
					},
					style: "primary",
					text: "Create account",
				},
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("onboarding.create_account.skip");
							UI().pushView(OnboardingComplete);
						});
					},
					style: "primary",
					text: "Skip this step for now",
				},
			]}
		>
			<CMText class={"body-text"}>
				Creating an account will let you access your repertoire from other devices and prevent you
				from losing it if you clear your browser history.
			</CMText>
		</SidebarTemplate>
	);
};

type TFake = any;
export const Dropdown: Component<{
	choice: TFake;
	choices: TFake[];
	title?: string;
	onSelect: (_: TFake) => void;
	renderChoice: (_: TFake, inDropdown: boolean, onPress: (e: Event) => void) => JSX.Element;
}> = (props) => {
	const [isOpen, setIsOpen] = createSignal(false);
	const [ref, setRef] = createSignal(null);

	useOutsideClick(ref, (e: Event) => {
		if (isOpen()) {
			setIsOpen(false);
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	});
	return (
		<div style={stylex(c.zIndex(10), c.relative)} class={"col"}>
			<p class={"text-secondary pb-2 text-sm font-bold"}>{props.title}</p>
			<div
				class={clsx("bg-gray-4 row cursor-pointer items-center rounded-sm px-4")}
				ref={setRef}
				use:onClick={() => {
					setIsOpen(!isOpen());
				}}
			>
				<div class={clsx("pointer-events-none")}>
					{props.renderChoice(props.choice, false, noop)}
				</div>
				<Spacer width={8} />
				<i class="fas fa-angle-down" style={stylex(c.fontSize(18), c.fg(c.gray[80]))} />
				<Motion
					animate={{ opacity: isOpen() ? 1 : 0 }}
					style={stylex(c.top("calc(22px)"), c.gridColumn({ gap: 0 }))}
					class={clsx(
						"bg-gray-4  p-2 absolute min-w-fit z-100 inset-x-0 rounded-[4px] items-stretch border border-solid border-gray-26 max-h-[320px] overflow-y-auto",
						!isOpen() && "pointer-events-none",
					)}
				>
					<For each={props.choices}>
						{(c) => (
							<div class={clsx("&hover:bg-gray-16 ")}>
								{props.renderChoice(c, true, (e) => {
									props.onSelect(c);
									setIsOpen(false);
									e?.preventDefault();
									e?.stopPropagation();
								})}
							</div>
						)}
					</For>
				</Motion>
			</div>
		</div>
	);
};

const ChooseColorOnboarding = () => {
	onMount(() => {
		trackEvent("onboarding.choose_color.shown");
	});
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Which color would you like to import?"
			actions={SIDES.map((side: Side) => ({
				onPress: () => {
					quick((s) => {
						trackEvent("onboarding.choose_color", { color: side });
						s.repertoireState.onboarding.side = side;
						UI().pushView(PGNImportOnboarding, {
							props: { side },
						});
					});
				},
				text: capitalize(side),
				style: "primary",
			}))}
		/>
	);
};

export const OnboardingComplete = () => {
	const responsive = useResponsiveV2();
	freezeWhileShown(OnboardingComplete);
	const bullets = () => {
		const bullets: JSXElement[] = [];
		bullets.push("Keep adding moves to your repertoire to get to 100%");
		bullets.push(
			<>
				Practice every day {!responsive().isMobile && "(download our apps to practice on the go)"}
			</>,
		);

		return bullets;
	};
	onMount(() => {
		trackEvent("onboarding.complete.shown");
		// @ts-ignore
		if (window.fbq) {
			// @ts-ignore
			window.fbq("trackCustom", "onboarding.complete.shown");
		}
	});
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Your Chessbook is ready to go!"
			actions={[
				{
					text: "Continue",
					style: "primary",
					onPress: () => {
						quick((s) => {
							trackEvent("onboarding.complete.continue");
							s.repertoireState.onboarding.isOnboarding = false;
							animateSidebar("left");
							s.repertoireState.backToOverview();
						});
					},
				},
			]}
		>
			<p class={"body-text"}>You've made a great start towards mastering the opening!</p>
			<Spacer height={16} />
			<p class={"body-text font-bold"}>Recommended next steps:</p>
			<Spacer between={["body-text", "bullets"]} />
			<div class={"space-y-2"}>
				<For each={bullets()}>{(bullet) => <Bullet>{bullet}</Bullet>}</For>
			</div>
		</SidebarTemplate>
	);
};

export const ImportSuccessOnboarding = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const repertoire = () =>
		BROWSING_STATE().getActiveRepertoire() ??
		REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!);
	onMount(() => {
		trackEvent("onboarding.import_success.shown");
	});
	freezeWhileShown(ImportSuccessOnboarding);
	const grade = () =>
		REPERTOIRE_STATE().repertoireGrades[
			REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!)?.id!
		];
	const missName = () => {
		const biggestMiss = grade()?.biggestMiss;
		if (!biggestMiss) {
			return null;
		}
		const { name } = useLineEcoCode(Line.fromPgn(first(biggestMiss?.lines)!))() ?? {};
		return name;
	};
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Import successful"
			actions={
				onboarding().isOnboarding
					? [
							{
								onPress: () => {
									quick((s) => {
										animateSidebar("right");
										trackEvent("onboarding.import_success.continue");
										s.repertoireState.browsingState.goToBuildOnboarding();
									});
								},
								text: "Go to the biggest gap in your repertoire",
								right: missName(),
								style: "primary",
							},
						]
					: [
							{
								onPress: () => {
									quick((s) => {
										animateSidebar("left");
										s.repertoireState.backToOverview();
									});
								},
								text: "Continue",
								style: "primary",
							},
						]
			}
		>
			<BigBar repertoireId={repertoire()!.id} type="completion" />
			<Show when={onboarding().isOnboarding}>
				<Spacer height={24} />
				<HowToComplete />
			</Show>
		</SidebarTemplate>
	);
};

export const HowToComplete = (props: {
	miss?: { name: string; incidence: number } | null | undefined;
}) => {
	const [threshold] = useUserState((s) => [s.getCurrentThreshold()]);
	const bullets = () => [
		<>
			Your goal is to cover any positions which occur in at least{" "}
			<b>{renderThreshold(threshold())} games</b>.
		</>,
		props.miss ? (
			<>
				Your biggest gap is in the <b>{props.miss.name}</b>, which you’ll see in{" "}
				<b>{renderThreshold(threshold())} games </b>
			</>
		) : (
			<>This was set based on your rating but you can always change it later.</>
		),
	];
	return (
		<>
			<CMText class={"body-text font-bold"}>How to complete your repertoire:</CMText>
			<div style={stylex(c.gridColumn({ gap: 8 }), c.pt(12))}>
				{bullets().map((bullet) => (
					<Bullet>{bullet}</Bullet>
				))}
			</div>
		</>
	);
};

export const FirstLineSavedOnboarding = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	onMount(() => {
		trackEvent("onboarding.first_line_saved.shown");
	});
	freezeWhileShown(FirstLineSavedOnboarding);
	const repertoire = () => REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!);
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="You've added your first moves!"
			actions={[
				{
					onPress: () => {
						trackEvent("onboarding.first_line_saved.continue");
						quick((_s) => {
							UI().pushView(PracticeIntroOnboarding);
						});
					},
					text: "Ok, got it",
					style: "primary",
				},
			]}
		>
			<BigBar repertoireId={repertoire()!.id} type="completion" />
			<Spacer height={24} />
			<HowToComplete />
		</SidebarTemplate>
	);
};

export const PracticeIntroOnboarding = () => {
	const currentLine = () => BROWSING_STATE().chessboard.getMoveLog();
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const activeRepertoire = () =>
		BROWSING_STATE().getActiveRepertoire() ??
		REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!);
	onMount(() => {
		trackEvent("onboarding.practice_intro.shown");
	});
	freezeWhileShown(PracticeIntroOnboarding);
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Now let's practice the moves you've added…"
			actions={[
				{
					onPress: () => {
						quick((s) => {
							trackEvent("onboarding.practice_intro.continue");
							s.repertoireState.reviewState.reviewLine(currentLine(), activeRepertoire()!);
						});
					},
					text: "Practice these moves",
					style: "focus",
				},
			]}
		>
			<CMText class="body-text">
				Chessbook uses spaced repetition, a scientifically proven technique to memorize openings
				quickly and thoroughly.
			</CMText>
		</SidebarTemplate>
	);
};

const _AskAboutExistingRepertoireOnboarding = () => {
	onMount(() => {
		trackEvent("onboarding.ask_about_existing_repertoire.shown");
	});
	freezeWhileShown(_AskAboutExistingRepertoireOnboarding);
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Do you want to import an existing repertoire?"
			actions={[
				{
					onPress: () => {
						trackEvent("onboarding.ask_about_existing_repertoire.has_existing");
						quick((_s) => {
							UI().pushView(ChooseImportSourceOnboarding);
						});
					},
					text: "Yes, import an existing repertoire",
					style: "primary",
				},
				{
					onPress: () => {
						trackEvent("onboarding.ask_about_existing_repertoire.no_existing");
						quick((s) => {
							animateSidebar("right");
							s.repertoireState.browsingState.goToBuildOnboarding();
						});
					},
					text: "No, I'll start from scratch",
					style: "primary",
				},
			]}
		>
			<CMText class="body-text">
				You can upload a PGN, or connect your Lichess account to add the openings you play
				automatically.
			</CMText>
		</SidebarTemplate>
	);
};

export const ChooseImportSourceOnboarding = (props: { side?: Side }) => {
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId;

	const onboarding = () => REPERTOIRE_STATE().onboarding;
	onMount(() => {
		if (onboarding().isOnboarding) {
			trackEvent("onboarding.choose_import_source.shown");
		}
	});
	freezeWhileShown(ChooseImportSourceOnboarding);
	const [isLoading, setIsLoading] = createSignal(false);
	return (
		<SidebarTemplate
			header="How do you want to import your repertoire? "
			loading={isLoading()}
			actions={[
				{
					onPress: () => {
						quick((_s) => {
							if (props.side && showUpgradeIfNeeded(props.side)) {
								return;
							}
							trackEvent("onboarding.choose_import_source.pgn");
							if (props.side) {
								UI().pushView(PGNImportOnboarding, {
									props: {
										side: props.side,
									},
								});
							} else {
								UI().pushView(ChooseColorOnboarding, {});
							}
						});
					},
					text: "From a PGN file",
					style: "primary",
				},
				{
					onPress: () => {
						trackEvent("onboarding.choose_import_source.lichess_study");
						quick((s) => {
							if (props.side && showUpgradeIfNeeded(props.side)) {
								return;
							}
							if (
								s.userState.user?.lichessUsername &&
								!s.userState.user?.lichessScopes?.includes("study:read")
							) {
								UI().pushView(LichessNeedNewScopes, {
									props: {
										authState: {
											source: onboarding().isOnboarding ? "onboarding-import" : "import",
											repertoireId: activeRepertoireId(),
											scopes: DEFAULT_LICHESS_SCOPES,
											importType: "study",
										},
									},
								});
							} else if (s.userState.user?.lichessUsername) {
								UI().pushView(SelectLichessStudy, {
									props: { repertoireId: activeRepertoireId() },
								});
							} else {
								s.userState.authWithLichess({
									source: onboarding().isOnboarding ? "onboarding-import" : "import",
									repertoireId: activeRepertoireId(),
									importType: "study",
									side: props.side,
								});
							}
						});
					},
					text: "From a Lichess study",
					style: "primary",
				},
				{
					onPress: () => {
						trackEvent("onboarding.choose_import_source.lichess");
						quick((s) => {
							if (props.side && showUpgradeIfNeeded(props.side)) {
								return;
							}
							if (s.userState.user?.lichessUsername) {
								setIsLoading(true);
								s.repertoireState.importIntoRepertoire({
									siteGamesSource: "lichess",
									repertoireId: activeRepertoireId(),
								});
							} else {
								s.userState.authWithLichess({
									source: onboarding().isOnboarding ? "onboarding-import" : "import",
									importType: "games",
									repertoireId: activeRepertoireId(),
									side: props.side,
								});
							}
						});
					},
					text: "From my Lichess games",
					style: "primary",
				},
				{
					onPress: () => {
						trackEvent("onboarding.choose_import_source.chesscom_games");
						quick((s) => {
							if (props.side && showUpgradeIfNeeded(props.side)) {
								return;
							}
							if (s.userState.user?.chesscomUsername) {
								setIsLoading(true);
								s.repertoireState.importIntoRepertoire({
									siteGamesSource: "chesscom",
									repertoireId: activeRepertoireId(),
								});
							} else {
								UI().pushView(ConnectChesscom, {
									props: { intention: "import", repertoireId: activeRepertoireId() },
								});
							}
						});
					},
					text: "From my Chess.com games",
					style: "primary",
				},
				// {
				// 	onPress: () => {
				// 		trackEvent("onboarding.choose_import_source.chesscom");
				// 		quick((s) => {
				// 			UI().pushView(ImportOnboarding, {
				// 				props: {
				// 					importType: SidebarOnboardingImportType.ChesscomUsername,
				// 				},
				// 			});
				// 		});
				// 	},
				// 	text: "From my chess.com games",
				// 	style: "primary",
				// },
				...(onboarding().isOnboarding
					? [
							{
								onPress: () => {
									trackEvent("onboarding.choose_import_source.nevermind");
									quick((s) => {
										animateSidebar("right");
										s.repertoireState.browsingState.goToBuildOnboarding();
									});
								},
								text: "Nevermind, skip this for now",
								style: "primary",
							} as SidebarAction,
						]
					: []),
			]}
		/>
	);
};

export const PGNImportOnboarding = (props: {
	side: Side;
	comingFromOauth?: boolean;
}) => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const activeSide = () => BROWSING_STATE().activeSide;
	const side = () => activeSide() || onboarding().side;
	const activeRepertoireId = () =>
		BROWSING_STATE().activeRepertoireId ??
		REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!)?.id;
	const repertoire = () => REPERTOIRE_STATE().repertoires?.[activeRepertoireId()!];

	const [loading, setLoading] = createSignal(null as string | boolean | null);
	const [pgn, setPgn] = createSignal("");

	freezeWhileShown(PGNImportOnboarding);
	onMount(() => {
		trackEvent("onboarding.import_pgn.shown");
	});
	const { header, actions } = destructure(() => {
		let header: string | null = null;
		let actions: SidebarAction[] = [];
		header = `Please upload your ${repertoire()?.name || side()} repertoire`;
		if (pgn()) {
			actions.push({
				text: "Submit",
				onPress: () => {
					importFromPgn();
					trackEvent("onboarding.import_pgn.submit");
				},
				style: "primary",
			});
		}
		if (loading()) {
			actions = [];
		}
		return { header, actions };
	});

	const importFromPgn = () => {
		setLoading("Importing");
		quick((s) => {
			const params = {
				pgn: pgn(),
				repertoireId: activeRepertoireId()!,
			};
			s.repertoireState.importIntoRepertoire(params).catch(() => {
				setLoading(false);
				trackEvent("onboarding.import_pgn.error");
			});
			trackEvent("import.from_pgns");
		});
	};

	return (
		<SidebarTemplate bodyPadding={true} header={header()} actions={actions()} loading={loading()}>
			<div class={"flex flex-col space-y-8"}>
				<PGNUpload onChange={setPgn} side={props.side} />
			</div>
		</SidebarTemplate>
	);
};

export const TrimRepertoireOnboarding = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const activeRepertoireId = () =>
		BROWSING_STATE().activeRepertoireId ??
		REPERTOIRE_STATE().getDefaultRepertoire(onboarding().side!)?.id;
	freezeWhileShown(TrimRepertoireOnboarding);
	const [userThreshold] = useUserState((s) => [s.getCurrentThreshold()]);
	onMount(() => {
		trackEvent("onboarding.trim_repertoire.shown");
	});
	const header = "Do you want to trim your repertoire?";
	const body = (
		<CMText class="body-text">
			We can trim your repertoire to only the moves you're likely to see. This can be a good option
			if you have a large repertoire from other software.
		</CMText>
	);
	const [loading, setLoading] = createSignal(false);

	const trimToThreshold = (threshold: number) => {
		trackEvent("onboarding.trim_repertoire.trimmed", { threshold });
		setLoading(true);
		quick((s) => {
			s.repertoireState.trimRepertoire(threshold, activeRepertoireId()!).then(() => {
				animateSidebar("right");
				UI().clearViews();
				UI().pushView(ImportSuccessOnboarding);
			});
		});
	};

	const actions = () => {
		let actions: SidebarAction[] = [];
		// TODO: this isn't quite accurate since danger isn't accounted for,
		// should have the same formula on the frontend, and include danger in
		// repertoire response
		const numMoves = REPERTOIRE_STATE().getNumResponsesBelowThreshold(
			userThreshold(),
			activeRepertoireId()!,
		);
		actions.push({
			text: "Trim rare lines below your coverage goal",
			subtext: `${numMoves} moves will be trimmed`,
			onPress: () => {
				trimToThreshold(userThreshold());
			},
			style: "primary",
		});
		actions.push({
			text: `No thanks, I'll keep my whole repertoire`,
			onPress: () => {
				trackEvent("onboarding.trim_repertoire.skip");
				quick((_s) => {
					animateSidebar("right");
					UI().clearViews();
					UI().pushView(ImportSuccessOnboarding);
				});
			},
			style: "primary",
		});
		if (loading()) {
			actions = [];
		}
		return actions;
	};
	return (
		<SidebarTemplate bodyPadding header={header} actions={actions()} loading={loading()}>
			{body}
		</SidebarTemplate>
	);
};

const PGNUpload = (props: { onChange: (pgn: string) => void; side: Side }) => {
	const [textInputPgn, setTextInputPgn] = createSignal<string | null>("");
	const [pgnUploadRef, setPgnUploadRef] = createSignal(null as HTMLInputElement | null);
	const [hasUploaded, setHasUploaded] = createSignal(false);
	freezeWhileShown(PGNUpload);
	createEffect(() => {
		const input = pgnUploadRef();
		if (input) {
			input.addEventListener("change", async (e) => {
				const file = input.files?.[0];
				if (file) {
					const body = await file.text();
					setTextInputPgn(null);
					props.onChange(body);
					setHasUploaded(true);
					return true;
				}
				e.preventDefault();
			});
		}
	});
	return (
		<div>
			<div class={"bg-gray-6 rounded-sm p-4"}>
				<div
					class={
						"border-gray-24 border-1 &hover:bg-gray-12 row relative h-20 w-full items-center justify-center rounded-sm border-dashed transition-colors"
					}
				>
					<i class={clsx("fas mr-2", hasUploaded() ? "fa-check" : "fa-upload")} />
					{hasUploaded() ? "Uploaded" : "Upload"}
					<input
						type="file"
						ref={setPgnUploadRef}
						class={"absolute z-10 h-24 h-full w-full cursor-pointer opacity-0"}
					/>
				</div>
				<div class={"row my-4 items-center space-x-2"}>
					<div class={"bg-gray-18 h-1px grow"} />
					<p class={"text-secondary  ext-xs font-semibold"}>Or</p>
					<div class={"bg-gray-18 h-1px grow"} />
				</div>
				<TextArea
					placeholder="Paste your PGN here"
					onInput={(v) => {
						setTextInputPgn(v.target.value);
						props.onChange(v.target.value);
						setHasUploaded(false);
					}}
					class=" mt-2 w-full rounded-sm border border-gray-400"
					inputClass={"bg-gray-16"}
					// @ts-ignore
					value={textInputPgn()}
				/>
			</div>
		</div>
	);
};
