import type { PieceSymbol } from "@lubert/chess.ts";
import type { Square } from "@lubert/chess.ts/dist/types";
import { drop, dropRight, findIndex, take } from "lodash-es";
import type { LichessMistake } from "~/types/GameMistake";
import { Line } from "~/types/Line";
import type { RepertoireMove } from "~/types/RepertoireMove";
import type { Side } from "~/types/Side";
import { USER_STATE } from "./app_state";
import { type FrontendSettingOption, ReviewStyle } from "./frontend_settings";
import type { MetaPlan } from "./plans";

export type QuizGroup = QuizGroupPlans | QuizGroupMoves;

export interface QuizGroupPlans extends QuizGroupBase {
	blockedByFreeTier?: boolean;
	plans: QuizPlan[];
	remainingPlans: QuizPlan[];
	completedPlans: QuizPlan[];
	lichessMistake?: undefined;
}

export type ReviewMove = RepertoireMove & {
	learning?: boolean;
};

export interface QuizGroupMoves extends QuizGroupBase {
	blockedByFreeTier?: boolean;
	moves: ReviewMove[];
	learningMode?: boolean;
	lichessMistake?: LichessMistake;
}

export interface QuizGroupBase {
	line: string[];
	epd: string;
	side: Side;
	// i.e. generated for the purpose of getting you to a specific position, not a "due" move
	synthetic?: boolean;
	precedingGroups?: QuizGroup[];
}

export interface QuizPlan {
	type: "piece_movement" | "castling";
	metaPlan: MetaPlan;
	piece: PieceSymbol;
	toSquares: Square[];
	options?: Square[];
	fromSquare: Square;
}

export interface QuizMoves {
	moves: RepertoireMove[];
}

export interface QuizItem {
	move?: RepertoireMove;
}

export const countQueue = (queue: QuizGroup[]) => {
	return queue
		.map((m) => {
			const moves = Quiz.getMoves(m);
			if (moves) {
				return moves.length;
			}
			return 1;
		})
		.reduce((a, b) => a + b, 0);
};

export namespace Quiz {
	export const getRemainingPlans = (quizGroup: QuizGroup, planIndex: number): QuizPlan[] | null => {
		const plans = Quiz.getPlans(quizGroup);
		return drop(plans, planIndex);
	};

	export const getCompletedPlans = (quizGroup: QuizGroup, planIndex: number): QuizPlan[] => {
		const plans = Quiz.getPlans(quizGroup);
		return take(plans, planIndex);
	};

	export const getMoves = (quizGroup: QuizGroup): ReviewMove[] | null => {
		if ("moves" in quizGroup) {
			return (quizGroup as QuizGroupMoves).moves;
		}

		return null;
	};

	export const getPlans = (quizGroup: QuizGroup): QuizPlan[] | null => {
		if ("plans" in quizGroup) {
			return (quizGroup as QuizGroupPlans).plans;
		}

		return null;
	};

	export const isPlansQuiz = (quizGroup: QuizGroup): boolean => {
		return "plans" in quizGroup;
	};

	export const appendExpandedQuizGroup = (
		terminalGroup: QuizGroup | null,
		group: QuizGroup,
	): QuizGroup[] => {
		const reviewStyleSetting = USER_STATE().getFrontendSetting(
			"reviewStyle",
		) as FrontendSettingOption<ReviewStyle>;
		if (reviewStyleSetting.value !== ReviewStyle.EntireLine) {
			return [group];
		}
		let groups = [...(group.precedingGroups ?? []), group];
		if (!terminalGroup) {
			return groups;
		}
		const move = Quiz.getMoves(terminalGroup)?.[0];
		const dropOffPgn = Line.toPgn([...terminalGroup.line, ...(move ? [move.sanPlus] : [])]);
		const precedingGroups = group.precedingGroups ?? [];
		if (dropOffPgn === Line.toPgn(dropRight(group.line, 1))) {
			return [group];
		}
		if (group.precedingGroups) {
			// console.log("q.precedingGroups", logProxy(map(groups, (q: QuizGroup) => Line.toPgn(q.line))), Quiz.getMoves(q))
			const continuationIndex = findIndex(
				precedingGroups,
				(_q: QuizGroup) => Line.toPgn(dropRight(group.line, 1)) === dropOffPgn,
			);
			if (continuationIndex !== -1) {
				groups = [...(group.precedingGroups ?? []).slice(continuationIndex), group];
			}
		}
		return groups;
	};
}
