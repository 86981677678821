import { type ComponentProps, Show, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { trackEvent } from "~/utils/trackEvent";
import { AdvancedRepertoireSettings } from "./AdvancedRepertoireSettings";
import { type LabeledBarIdentifier, genLabeledBarKey } from "./AnimatedBarPiece";
import { BigBar } from "./BigBar";
import { FooterActions } from "./FooterActions";
import { PreAdd } from "./PreAdd";
import { PreReview } from "./PreReview";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { RepertoireCharacteristics } from "./RepertoireCharacteristics";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { ChooseImportSourceOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";
import { TemplateSidebarView } from "./TemplateSidebarView";
import { isDevelopment } from "~/utils/env";
import { ReviewText } from "./ReviewText";

export const RepertoireOverview: EnrichedComponent<null> = () => {
	onBack(() => {
		quick((_s) => {
			animateSidebar("left");
		});
	});

	const side = () => BROWSING_STATE().activeSide;
	const _textClasses = "text-primary font-semibold";
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const grade = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!];
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const numMovesDueFromHere = () =>
		REPERTOIRE_STATE().numMovesDueFromEpd[activeRepertoireId()!]?.[START_EPD];
	const earliestDueDate = () =>
		REPERTOIRE_STATE().earliestReviewDueFromEpd[activeRepertoireId()!]?.[START_EPD];

	const functionallyEmpty = () => REPERTOIRE_STATE().numMyEnabledMoves[activeRepertoireId()!] === 0;
	const _genBarKey = (name: LabeledBarIdentifier) => genLabeledBarKey(name, activeRepertoireId());
	const _progressState = () => BROWSING_STATE().repertoireProgressState[activeRepertoireId()];
	const _mastery = () => REPERTOIRE_STATE().masteryFromEpd[activeRepertoireId()][START_EPD];

	const buildActions = createMemo(() => {
		const actions: SidebarAction[] = [];
		actions.push({
			onPress: () => {
				quick((_s) => {
					BROWSING_STATE().startBrowsing(activeRepertoireId()!, "build");
					if (empty()) {
						UI().pushView(RepertoireBuilder);
						trackEvent("side_overview.start_building");
					} else {
						UI().pushView(PreAdd);
						trackEvent("side_overview.keep_building");
					}
				});
			},
			// right: numMoves() > 0 ? `${numMoves()} Added` : undefined,
			right: (
				<div class="row items-center gap-2 text-secondary">
					<p> {numMoves()} Saved</p>
					<i class="fa fa-arrow-right" />
				</div>
			),
			text: empty()
				? "Start building your repertoire"
				: biggestMiss()
					? "Continue building"
					: "Continue building",
			style: "primary",
		});
		if (empty()) {
			actions.push({
				text: empty() ? "Import" : "Import / export",
				onPress: () => {
					quick((_s) => {
						if (empty()) {
							trackEvent("side_overview.import");
							UI().pushView(ChooseImportSourceOnboarding, {
								props: { side: side() },
							});
							return;
						}
						UI().pushView(TemplateSidebarView, {
							props: {
								header: "Import / export",
								actions: [
									{
										onPress: () => {
											trackEvent("side_overview.import");
											UI().pushView(ChooseImportSourceOnboarding, {
												props: { side: side() },
											});
										},
										text: "Import",
										style: "secondary",
									},
									{
										onPress: () => {
											quick((s) => {
												trackEvent("side_overview.export");
												s.repertoireState.exportPgn(repertoire().id);
											});
										},
										text: "Export to PGN",
										style: "secondary",
									},
								],
							},
						});
					});
				},
				style: "primary",
			});
		}

		// if (!isNil(biggestMiss())) {
		// 	actions.push({
		// 		onPress: () => {
		// 			quick((_s) => {
		// 				// Logic for going to biggest gap and editing
		// 				// You'll need to implement this based on your application's structure
		// 			});
		// 		},
		// 		text: "Address biggest gap",
		// 		style: "primary",
		// 	});
		// }

		return actions;
	});

	const reviewActions = createMemo(() => {
		const actions: SidebarAction[] = [];
		if (!functionallyEmpty()) {
			actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent("side_overview.start_review");
						UI().pushView(PreReview, {
							props: { repertoireId: activeRepertoireId() ?? null },
						});
					});
				},
				text: "Continue learning",
				// right: (
				// ),
				right: (
					<div class="row items-center gap-2 text-secondary">
						<ReviewText
							date={earliestDueDate()}
							numDue={numMovesDueFromHere()}
							overview={true}
							textColor="text-secondary"
							hideIcon
						/>
						<i class="fa fa-arrow-right" />
					</div>
				),
				style: "primary",
			});
			// actions.push({
			// 	onPress: () => {
			// 		quick((_s) => {
			// 			trackEvent("side_overview.view_plans_and_model_games");
			// 			UI().pushView(PlansBrowser);
			// 			BROWSING_STATE().startBrowsing(activeRepertoireId()!, "plans_and_model_games");
			// 		});
			// 	},
			// 	text: "View plans and model games",
			// 	style: "primary",
			// });
		}
		return actions;
	});

	const miscActions = createMemo(() => {
		const actions: ComponentProps<typeof FooterActions>["actions"] = [];
		actions.push({
			text: "Repertoire settings...",
			onPress: () => {
				quick((_s) => {
					UI().pushView(AdvancedRepertoireSettings);
				});
			},
		});
		return actions;
	});

	// You can then combine these getters if needed:
	return (
		<SidebarTemplate header={`${repertoire()?.name} repertoire`} actions={[]} bodyPadding={false}>
			<Spacer height={12} />
			<Show when={!empty()}>
				<div class="padding-sidebar pb-6">
					<BigBar repertoireId={activeRepertoireId()!} type="completion" />
				</div>
			</Show>
			<SidebarActions actions={buildActions()} />
			<Show when={!empty()}>
				<Spacer between={["actions", "bar"]} />
				<div class="padding-sidebar pb-6">
					<BigBar repertoireId={activeRepertoireId()!} type="mastery" />
				</div>
				<SidebarActions actions={reviewActions()} />
			</Show>
			<Show when={grade().characteristics && USER_STATE().flagEnabled("characteristics")}>
				<Spacer height={36} />
				<div class="padding-sidebar">
					<SidebarHeader>
						<h2 class="text-base">Repertoire stats</h2>
					</SidebarHeader>
				</div>
				<Spacer height={24} />
				<RepertoireCharacteristics
					class="padding-sidebar"
					repertoireId={activeRepertoireId()}
					exclude={isDevelopment ? ["sharpness"] : ["sharpness", "learnability"]}
				/>
				<Spacer between={["table", "actions"]} />
			</Show>
			<FooterActions actions={miscActions()} />
		</SidebarTemplate>
	);
};

registerViewMode(RepertoireOverview, "side_overview");
