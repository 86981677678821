import posthog from "posthog-js";
import { createSignal } from "solid-js";
import { APP_STATE } from "./app_state";

// biome-ignore lint: ignore Object type
export const identify = (props: Object) => {
	console.debug("Identify: ", props);

	posthog.setPersonProperties(props);
};

export const [userIdentifiedById, setUserIdentifiedById] = createSignal<string | null>(null);

// biome-ignore lint: ignore Object type
export const identifyOnce = (props: Object) => {
	console.debug("Identify once: ", props);
	// const user = APP_STATE().userState.user;
	// if (!user) {
	// 	console.debug("No user to identify");
	// 	return;
	// }
	// posthog.capture("$set", {
	// 	$set_once: props,
	// });
	posthog.setPersonProperties({}, props);
};

export const identifyUser = () => {
	const user = APP_STATE().userState.user;
	if (!user) {
		console.debug("No user to identify");
		return;
	}
	if (userIdentifiedById()) {
		console.debug("User already identified by id");
		return;
	}
	if (user.id) {
		console.debug("Identifying user by id: ", user.id);
		setUserIdentifiedById(user.id);
		// posthog.identify(user.id);
		return;
	}
};
