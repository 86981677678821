import { Show, onMount } from "solid-js";
import { UI, quick } from "~/utils/app_state";
import { trackEvent } from "~/utils/trackEvent";
import { RepertoireReview } from "./RepertoireReview";
import type { SidebarAction } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";
import { UpgradeSubscriptionView } from "./UpgradeSubscriptionView";

export const ReviewsBlockedByFreeTierWarning = (props: { total: number; blocked: number }) => {
	onMount(() => {
		trackEvent("reviews_blocked_by_free_tier_warning.shown");
	});
	let allBlocked = () => props.total === props.blocked;
	let actions = () => {
		let actions: SidebarAction[] = [];
		actions.push({
			text: "Upgrade to practice all your moves",
			onPress: () => {
				trackEvent("reviews_blocked_by_free_tier_warning.upgrade");
				quick((_s) => {
					UI().pushView(UpgradeSubscriptionView);
				});
			},
			style: allBlocked() ? "focus" : "primary",
		});
		if (!allBlocked()) {
			actions.push({
				text: `Practice ${props.total - props.blocked} moves for free`,
				onPress: () => {
					trackEvent("reviews_blocked_by_free_tier_warning.review");
					quick((_s) => {
						UI().pushView(RepertoireReview);
					});
				},
				style: "secondary",
			});
		}
		return actions;
	};
	return (
		<SidebarTemplate
			header={
				allBlocked()
					? "You've reached the limit on the free plan"
					: "You've reached the limit on the free plan"
			}
			bodyPadding={true}
			actions={actions()}
		>
			<Show when={!allBlocked()}>
				<p class="body-text">Free users can review their most common 100 moves per color.</p>
			</Show>
			<Show when={allBlocked()}>
				<p class="body-text">
					Free users can review their most common 100 moves per color, but none of these moves meet
					that criteria.
				</p>
			</Show>
		</SidebarTemplate>
	);
};
