import { createSignal } from "solid-js";
import { UI, quick } from "~/utils/app_state";
import client from "~/utils/client";
import { c, stylex } from "~/utils/styles";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextInput } from "./TextInput";
import { InputError } from "./forms/InputError";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import toast from "solid-toast";
import * as yup from "yup";
import type { Repertoire } from "~/types/Repertoire";
import type { FetchRepertoireResponse } from "~/utils/repertoire_state";
import { animateSidebar } from "./SidebarContainer";
import { Spacer } from "./Space";

type Form = {
	name: string;
	description: string;
};

export const CreateCourse = (props: { repertoire: Repertoire }) => {
	const onSubmit = (values: Form) => {
		setServerError("");
		client
			.post("/api/v1/openings/create_course", {
				name: values.name,
				description: values.description,
				repertoireId: props.repertoire.id,
			})
			.then(({ data: _data }: { data: FetchRepertoireResponse }) => {
				quick((_s) => {
					UI().clearViews();
					toast.success(`"${values.name}" course created`);
					animateSidebar("left");
				});
			})
			.catch((err) => {
				setServerError(err?.response?.data?.error ?? "Something went wrong");
			});
	};
	const { form, setFields, errors, createSubmitHandler } = createForm<Form>({
		initialValues: { name: props.repertoire.name, description: props.repertoire.name },
		onSubmit: onSubmit,
		extend: [
			validator({
				schema: yup.object({
					name: yup.string().label("Name").max(255).min(4).required(),
					description: yup.string().label("Description").required(),
				}),
			}),
		],
	});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	const [serverError, setServerError] = createSignal("");
	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: () => {
							handleSubmit();
						},
						text: "Create course",
						style: "focus",
					},
				]}
				header={"Create a course from this repertoire?"}
			>
				<div class="col items-center">
					<p class="body-text padding-sidebar">
						This will create a course with all the moves you currently have in this repertoire. You
						will not be able to change the course later.
					</p>

					<Spacer between={["header", "form"]} />
					<div class={" w-full self-stretch"}>
						<div style={stylex(c.br(4), c.px(0), c.py(0))}>
							<form ref={form} class={"col gap-8 padding-sidebar"}>
								<TextInput
									placeholder="The killer dutch"
									setFields={setFields}
									type="text"
									name="name"
									label="Course name"
									errors={errors()}
								/>
								<TextInput
									placeholder="Your description here"
									setFields={setFields}
									type="text"
									name="description"
									label="Description"
									errors={errors()}
								/>
								<InputError name={"Server error"} error={serverError()} class={"inline-block"} />
								<input type="submit" hidden />
							</form>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
