import { isEmpty } from "lodash-es";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { renderThreshold } from "~/utils/threshold";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { ConfirmDeleteRepertoire } from "./ConfirmDeleteRepertoire";
import { CreateCourse } from "./CreateCourse";
import { RenameRepertoire } from "./RenameRepertoire";
import { RepertoireChooseEloRanges } from "./RepertoireChooseEloRanges";
import { type SidebarActionGroup, SidebarActionGroups } from "./SidebarActionGroups";
import { TrimRepertoireOnboarding } from "./SidebarOnboarding";
import { CoverageSettings } from "./SidebarSettings";
import { SidebarTemplate } from "./SidebarTemplate";

export const AdvancedRepertoireSettings = () => {
	const side = () => BROWSING_STATE().activeSide;
	const textClasses = "text-primary font-semibold";
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const ratingRanges = () =>
		!isEmpty(repertoire().eloRanges) ? repertoire().eloRanges! : [USER_STATE().user!.eloRange];
	const threshold = () =>
		repertoire().coverageTarget ? repertoire().coverageTarget! : USER_STATE().getCurrentThreshold();
	const groups = () => {
		const groups: SidebarActionGroup[] = [];
		(() => {
			const group: SidebarActionGroup = {
				title: undefined,
				actions: [],
			};
			group.actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent("side_overview.rename_repertoire");
						UI().pushView(RenameRepertoire);
					});
				},
				text: <CMText class={clsx(textClasses)}>Repertoire name</CMText>,
				right: repertoire().name,
				style: "secondary",
			});
			if (!empty()) {
				group.actions.push({
					onPress: () => {
						quick((_s) => {
							trackEvent("side_overview.export");
							REPERTOIRE_STATE().exportPgn(repertoire().id);
						});
					},
					text: <CMText class={clsx(textClasses)}>Export to PGN</CMText>,
					right: null,
					style: "secondary",
				});
			}
			if (group.actions.length > 0) {
				groups.push(group);
			}
		})();
		(() => {
			const group: SidebarActionGroup = {
				title: "Advanced settings",
				actions: [],
			};
			group.actions.push({
				onPress: () => {
					quick((_s) => {
						UI().pushView(CoverageSettings, {
							props: { repertoireId: activeRepertoireId()! },
						});
					});
				},
				text: <CMText class={clsx(textClasses)}>Cover positions seen in</CMText>,
				right: `${renderThreshold(threshold())} games`,
				style: "secondary",
			});
			if (group.actions.length > 0) {
				groups.push(group);
			}
		})();
		(() => {
			if (!USER_STATE().flagEnabled("course_creation")) {
				return;
			}
			const group: SidebarActionGroup = {
				title: "Course creation",
				actions: [],
			};
			group.actions.push({
				onPress: () => {
					quick((_s) => {
						UI().pushView(RepertoireChooseEloRanges, {
							props: { repertoireId: activeRepertoireId()! },
						});
					});
				},
				text: <CMText class={clsx(textClasses)}>Select specific rating ranges</CMText>,
				right: ratingRanges().length > 1 ? "Multiple" : ratingRanges()[0],
				style: "secondary",
			});
			group.actions.push({
				onPress: () => {
					quick((_s) => {
						UI().pushView(CoverageSettings, {
							props: { repertoireId: activeRepertoireId()! },
						});
					});
				},
				text: <CMText class={clsx(textClasses)}>Cover positions seen in</CMText>,
				right: `${renderThreshold(threshold())} games`,
				style: "secondary",
			});
			group.actions.push({
				onPress: () => {
					quick((_s) => {
						UI().pushView(CreateCourse, {
							props: { repertoire: repertoire() },
						});
						return;
					});
				},
				text: "Create course from this repertoire",
				style: "secondary",
			});
			if (group.actions.length > 0) {
				groups.push(group);
			}
		})();
		(() => {
			const group: SidebarActionGroup = {
				title: "Danger zone",
				actions: [],
			};
			if (!empty()) {
				group.actions.push({
					onPress: () => {
						trackEvent("side_overview.trim");
						quick((_s) => {
							UI().pushView(TrimRepertoireOnboarding);
						});
					},
					text: <CMText class={clsx(textClasses)}>Trim repertoire</CMText>,
					right: null,
					style: "secondary",
				});
			}
			if (REPERTOIRE_STATE().hasMultipleRepertoires(side()!) || !empty()) {
				group.actions.push({
					onPress: () => {
						quick((_s) => {
							trackEvent("side_overview.delete_repertoire");
							UI().replaceView(ConfirmDeleteRepertoire);
						});
					},
					text: <CMText class={clsx(textClasses)}>Delete repertoire</CMText>,
					right: null,
					style: "secondary",
				});
			}
			if (group.actions.length > 0) {
				groups.push(group);
			}
		})();
		return groups;
	};
	return (
		<SidebarTemplate header={"Repertoire settings"} actions={[]} bodyPadding={false}>
			<SidebarActionGroups groups={groups()} />
		</SidebarTemplate>
	);
};
