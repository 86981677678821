import { Show, createEffect, createSignal } from "solid-js";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";

import { CountUp } from "countup.js";

const LAST_VALUES: Record<string, number> = {};

export const AnimatedBarPiece = (props: {
	value: number;
	textValue?: number;
	key: string;
	showFloatingValue?: boolean;
	isolated?: boolean;
	rounded?: boolean;
	color?: string;
	tooltip?: string;
	hideValue?: boolean;
	classBar?: string;
	classText?: string;
	formatValue?: (x: number) => string;
	decimalPlaces?: number;
}) => {
	let _color = () => props.color || "bg-gray-70";
	let [_animate, _setAnimate] = createSignal(false);
	let [animateValue, setAnimateValue] = createSignal(LAST_VALUES[props.key] ?? props.value);
	createEffect(() => {
		let lastValue = LAST_VALUES[props.key];
		if (props.value === lastValue || lastValue === undefined) {
			LAST_VALUES[props.key] = props.value;
			return;
		}
		setAnimateValue(lastValue);
		setTimeout(() => {
			LAST_VALUES[props.key] = props.value;
			setAnimateValue(props.value);
		}, 500);
	});
	const transitionClasses = `transition-all  duration-1000`;
	return (
		<>
			<div
				class={clsx(
					`w-full overflow-hidden h-full select-none relative`,
					props.classBar,
					transitionClasses,
					props.rounded && "rounded-sm",
				)}
				style={stylex(c.width(`${animateValue() * 100}%`))}
			></div>
			<Show when={props.showFloatingValue}>
				<div
					class={clsx(
						"absolute top-0  flex center -translate-x-1/2 -translate-y-1/2 bg-gray-85 py-1.5 w-9 rounded-sm leading-none shadow-lg",
						transitionClasses,
					)}
					style={stylex(c.left(`clamp(10px, ${animateValue() * 100}%, 100% - 10px)`))}
				>
					<AnimatedBarValue
						decimalPlaces={props.decimalPlaces}
						key={props.key}
						value={props.textValue ?? props.value * 100}
						class={clsx("text-inverse text-xs")}
						formatValue={(x) => x.toFixed(0)}
					/>
				</div>
			</Show>
			<Show when={!props.hideValue}>
				<div class={clsx("absolute top-0 bottom-0 flex center left-1.5")}>
					<AnimatedBarValue
						decimalPlaces={props.decimalPlaces}
						key={props.key}
						value={props.textValue ?? props.value * 100}
						class={props.classText}
						formatValue={props.formatValue}
					/>
				</div>
			</Show>
		</>
	);
};

export const AnimatedBarValue = (props: {
	value: number;
	key: string;
	class?: string;
	formatValue?: (x: number) => string;
	decimalPlaces?: number;
}) => {
	const key = () => `${props.key}-label`;
	createEffect(() => {});

	const formatValue = (x: number) => props.formatValue?.(x) ?? `${x.toFixed(0)}%`;
	createEffect(() => {
		let lastValue = LAST_VALUES[key()];
		if (props.value === lastValue || lastValue === undefined) {
			LAST_VALUES[key()] = props.value;
			counterRef()!.innerHTML = formatValue(props.value);
			return;
		}
		counterRef()!.innerHTML = formatValue(lastValue);
		setTimeout(() => {
			LAST_VALUES[key()] = props.value;
			if (counterRef()) {
				const countUp = new CountUp(counterRef()!, props.value, {
					startVal: lastValue,
					decimalPlaces: props.decimalPlaces ?? 1,
					formattingFn: (x) => formatValue(x),
				});
				countUp.start();
			}
		}, 500);
	});

	const [counterRef, setCounterRef] = createSignal(null as HTMLDivElement | null);
	return (
		<div
			class={clsx("inline", props.class)}
			ref={(x) => {
				setCounterRef(x);
			}}
		></div>
	);
};

export type LabeledBarIdentifier =
	| "completion"
	| "mastery"
	| "effectiveness"
	| "soundness"
	| "eval"
	| "wins"
	| "learnability"
	| "draws"
	| "sharpness";

export const genLabeledBarKey = (identifier: LabeledBarIdentifier, repertoireId: string) => {
	return `${identifier}-${repertoireId}`;
};
