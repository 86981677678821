import { For } from "solid-js";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";
import { Pressable } from "./Pressable";

export const FooterActions = (props: { actions: { text: string; onPress: () => void }[] }) => {
	return (
		<div style={stylex(c.row)} class={clsx("pt-3 space-x-4 padding-sidebar")}>
			<For each={props.actions}>
				{(action) => (
					<Pressable
						class={clsx("pb-1")}
						onPress={(e: Event) => {
							e.stopPropagation();
							action.onPress();
						}}
					>
						<CMText
							style={stylex(c.fontSize(12), c.weightSemiBold)}
							class="text-tertiary &hover:text-primary transition-colors"
						>
							{action.text}
						</CMText>
					</Pressable>
				)}
			</For>
		</div>
	);
};
