import * as Sentry from "@sentry/browser";
import { isDevelopment } from "./env";

export const devAssert = (condition: boolean, ...message: any[]) => {
	if (!condition) {
		console.error(
			"Assertion failed!",
			...message.map((m) => (typeof m === "string" ? m : JSON.stringify(m))),
		);

		Sentry.captureException(new Error(`Assertion failed! ${message}`));
		if (isDevelopment) {
			// biome-ignore lint: debugger is fine here
			debugger;
		}
	}
};

export function assertNotNull<T>(
	value: T | null | undefined,
	message?: string,
): asserts value is T {
	if (value === null || value === undefined) {
		console.error("Non-null assertion failed!", message);
		Sentry.captureException(new Error(`Non-null assertion failed! ${message}`));

		if (isDevelopment) {
			console.error(message, value !== null && value !== undefined);
			// biome-ignore lint: debugger is fine here
			debugger;
		}
	}
}
