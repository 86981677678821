import { Capacitor } from "@capacitor/core";
import posthog from "posthog-js";
import { APP_STATE } from "./app_state";
import { renderThreshold } from "./threshold";
import { identifyUser } from "./user_properties";

export const trackEvent = (name: string, props?: object) => {
	identifyUser();
	console.debug(
		`[EVENT] %c${name} %c ${Object.entries(props ?? {})
			.map(([k, v]) => `${k}=${v}`)
			.join(" | ")}`,
		"color: salmon; font-weight: bold;",
		"color: hsl(217, 92%, 76%); font-weight: bold;",
	);
	const user = APP_STATE().userState.user;
	let sets: any = {};
	if (user) {
		sets = {
			...sets,
			email: user.email,
			subscribed: user.subscribed,
			admin: user.isAdmin,
			rating_range: user.ratingRange,
			rating_system: user.ratingSystem,
			computed_rating: user.eloRange,
			theme: user.theme,
			piece_set: user.pieceSet,
			...(user.missThreshold
				? {
						coverage_target: `${renderThreshold(user.missThreshold / 100)}`,
					}
				: {}),
		};
	}
	const setsOnce: any = {};
	if (user?.subscribed) {
		setsOnce.ever_subscribed = true;
	}
	posthog.capture(name, {
		...props,
		$set: {
			platform: Capacitor.getPlatform(),
			...sets,
		},
		$set_once: {
			...setsOnce,
		},
	});
};
