import { isNil } from "lodash-es";
import { createMemo } from "solid-js";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { pluralize } from "~/utils/pluralize";
import { registerViewMode } from "~/utils/register_view_mode";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { type SidebarAction, useBiggestGapActions } from "./SidebarActions";
import { ChooseImportSourceOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";

export const PreAdd: EnrichedComponent<{ bogus: any }> = (_props) => {
	const _repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const side = () => BROWSING_STATE().activeSide;
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const actions = createMemo(() => {
		let actions: SidebarAction[] = [];
		const missActions = useBiggestGapActions();
		actions = [
			...actions,
			...missActions.map((a) => ({ ...a, style: "primary" as SidebarAction["style"] })),
		];
		actions.push({
			onPress: () => {
				quick((_s) => {
					UI().pushView(RepertoireBuilder);
					BROWSING_STATE().startBrowsing(activeRepertoireId()!, "build");
					if (empty()) {
						trackEvent("side_overview.start_building");
					} else {
						trackEvent("side_overview.keep_building");
					}
					return;
				});
			},
			right: pluralize(numMoves(), "move"),
			text: (
				<CMText>
					{empty()
						? "Start building your repertoire"
						: isNil(biggestMiss())
							? "Browse / add new moves"
							: "Browse / edit something else"}
				</CMText>
			),
			style: "primary",
		});
		actions.push({
			text: "Import",
			onPress: () => {
				quick((_s) => {
					trackEvent("side_overview.import");
					UI().pushView(ChooseImportSourceOnboarding, {
						props: { side: side() },
					});
				});
			},
			style: "secondary" as const,
		});
		return actions;
	});
	return (
		<SidebarTemplate
			header={empty() ? "Start building your repertoire" : "Build your repertoire"}
			actions={actions()}
			bodyPadding={true}
		/>
	);
};

registerViewMode(PreAdd, "build");
